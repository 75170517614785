var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.events.length > 0)?_c('div',{class:['company-events container-fluid', _vm.WidgetBackground[_vm.background]]},[_c('div',{class:{'container': _vm.isDesktop}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row flex-column-reverse flex-xl-row"},[_c('div',{class:['content col-12 col-xl-5 d-flex flex-column align-items-start',
                     'justify-content-start justify-content-xl-center row-gap-12',
                     _vm.isDesktop
                       ? ((_vm.WidgetTopGap[_vm.topGap]) + " " + (_vm.WidgetBottomGap[_vm.bottomGap]))
                       : 'px-4 py-5 px-sm-5']},[_c('div',{staticClass:"event-infos d-flex align-items-center column-gap-4 cursor-pointer",on:{"click":_vm.handleBaseUrl}},[_c('div',[_c('avatar-solo-widget',{attrs:{"default-icon":!_vm.FileResourceHelper
                    .getFullPath(_vm.events[_vm.selectedSubEditionIndex].logoFileResource, 'w56')
                    ? 'far fa-calendar-star' : '',"square":true,"src":_vm.FileResourceHelper
                    .getFullPath(_vm.events[_vm.selectedSubEditionIndex].logoFileResource, 'w56'),"icon-classes":"text-neutral-n-6-label icon-20","icon-container-classes":"icon-20","size":"56"}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('h4',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.events[_vm.selectedSubEditionIndex].name)+" ")]),_c('p',{staticClass:"m-0 paragraph-1 text-neutral-n-6-label text-left ellipsis"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])]),_c('h2',{staticClass:"m-0 font-weight-normal text-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c('div',{staticClass:"actions d-flex align-items-center gap-2 w-100 flex-wrap flex-md-nowrap"},[(_vm.geoZonesByEvent[_vm.events[_vm.selectedSubEditionIndex].code]
                  && _vm.geoZonesByEvent[_vm.events[_vm.selectedSubEditionIndex].code].length > 0)?_c('button-component',{staticClass:"flex-1",attrs:{"text":_vm.$t('actions.find-us-on-map'),"size":"lg","variant":"primary"},on:{"on-click":function($event){return _vm.findUsOnMap(_vm.events[_vm.selectedSubEditionIndex].code)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-location-dot","icon-classes":"icon-16"}})]},proxy:true}],null,false,1473587920)}):_vm._e(),_c('button-component',{staticClass:"flex-1",attrs:{"text":("" + (_vm.isVisited
                  ? _vm.$t('actions.tooltip.visited.remove')
                  : _vm.$t('actions.tooltip.visited.add'))),"variant":_vm.isVisited ? 'alt-2' : 'secondary',"size":"lg"},on:{"on-click":_vm.toggleVisited},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-location-check","icon-classes":"icon-16"}})]},proxy:true}],null,false,3792901033)})],1),(_vm.isDesktop && _vm.events.length > 1)?_c('div',{staticClass:"indicators d-flex align-items-center column-gap-4 mt-5"},_vm._l((_vm.events),function(val,i){return _c('span',{key:((val.uid) + "-" + i),class:_vm.selectedSubEditionIndex === i
                  ? 'bg-blue-b-3-primary-blue'
                  : 'bg-neutral-n-4-line'})}),0):_vm._e()]),_c('div',{class:['slides col-12 col-xl-6 offset-0 offset-xl-1 ',{'desktop': _vm.isDesktop}]},[_c('div',{staticClass:"w-100 h-100 position-relative"},[_c('img',{staticClass:"slide-item",attrs:{"src":_vm.events[_vm.selectedSubEditionIndex].bannerFileResource
                  ? _vm.FileResourceHelper
                    .getFullPath(_vm.events[_vm.selectedSubEditionIndex].bannerFileResource, 'w1200')
                  : '/img/placeholder.png',"alt":""}}),(_vm.events.length > 1)?_c('div',{staticClass:"w-100 h-100 d-flex flex-column align-items-center justify-content-center\n                position-absolute top-0 left-0 p-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('slider-arrow-component',{staticClass:"w-fit nav-prev",attrs:{"direction":"left","size":"lg"},on:{"on-click":function($event){return _vm.slide(-1)}}}),_c('slider-arrow-component',{staticClass:"w-fit nav-next",attrs:{"direction":"right","size":"lg"},on:{"on-click":function($event){return _vm.slide(1)}}})],1),(!_vm.isDesktop)?_c('div',{staticClass:"indicators d-flex align-items-center column-gap-4 position-absolute"},_vm._l((_vm.events),function(val,i){return _c('span',{key:((val.uid) + "-" + i),class:_vm.selectedSubEditionIndex === i
                      ? 'bg-blue-b-3-primary-blue'
                      : 'bg-neutral-n-4-line'})}),0):_vm._e()]):_vm._e()])])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }