



















































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import SubEdition from '@/models/graphql/SubEdition';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format } from 'date-fns';
import { namespace, State } from 'vuex-class';
import SliderArrowComponent from '@/components/SliderArrowComponent.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import { CommunityUserVisitedFilter } from '@/graphql/_Filters/CommunityUserVisitedFilter';
import CommunityUserVisited from '@/models/graphql/CommunityUserVisited';
import Geozone from '@/models/graphql/Geozone';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { BasicTypes } from '@/utils/types/BasicTypes';

const communityUserVisitedStore = namespace('CommunityUserVisitedStore');
const widgetDispatcherStore = namespace('WidgetDispatcherStore');

@Component({
  components: {
    SliderArrowComponent, FontAwesomeComponent, ButtonComponent, AvatarSoloWidget,
  },
})
export default class CompanyEventsComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'CompanyEventsWidgetStore';

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly exhibitor!: Exhibitor;

  @State
  private dateLocale!: Locale;

  @communityUserVisitedStore.Action
  private markAsVisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @communityUserVisitedStore.Action
  private markUnvisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @widgetDispatcherStore.Action
  private runGqlQuery!: (payload: { query: string; operationName: string }) => Promise<Record<string, BasicTypes[]>>;

  private FileResourceHelper = FileResourceHelper;

  private selectedSubEditionIndex = 0;

  private geoZonesByEvent: Record<string, Geozone[]> = {};

  private isVisited: string | null = null;

  private get events(): SubEdition[] {
    // eslint-disable-next-line no-underscore-dangle
    return this.exhibitor?._subEditions || [];
  }

  private get subtitle(): string {
    const result: string[] = [];
    if (this.events.length > 0) {
      const event = this.events[this.selectedSubEditionIndex];
      let start = null;
      let end = null;
      if (event.startTime) {
        start = DateTimeHelper.toUTC(new Date((event.startTime as unknown) as string));
      }
      if (event.endTime) {
        end = DateTimeHelper.toUTC(new Date((event.endTime as unknown) as string));
        if (start && end) {
          if (format(end, 'yyyy-MM-dd') === format(start, 'yyyy-MM-dd')) {
            result.push(
              format(start, this.$t('app.date.defaultDateFormat') as string, {
                locale: this.dateLocale,
              }),
            );
          } else if (format(end, 'yyyy') !== format(start, 'yyyy')) {
            result.push(
              `${format(start, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}
              ${this.$t('app.date.dateTimeSeparator')}
              ${format(end, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}`,
            );
          } else {
            result.push(
              `${format(start, this.$t('app.date.monthDayFull') as string, { locale: this.dateLocale })}
              ${this.$t('app.date.dateTimeSeparator')}
              ${format(end, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}`,
            );
          }
        }
      }
      if (event.venue && event.venue.name) {
        result.push((event.venue.name as unknown) as string);
      }
    }
    return result.join(' — ');
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('exhibitor')
  init(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.isVisited = this.exhibitor._isVisited ?? null;
    this.loadGeoZones();
  }

  private loadGeoZones(): void {
    if (this.exhibitor && this.events.length > 0) {
      const query = `exhibitor(uid: "${this.exhibitor.uid}") {
        ${this.events.map((e) => `eGeo_${e.id}: _geozones(subEditionUid: "${e.uid}") {
          uid
          id
          schemaCode
          name
          exhibitHall {
            id
            name
          }
          booth {
            id
            number
          }
        }`)}
      }`;
      this.runGqlQuery({
        operationName: 'LoadEventsGeoZones',
        query,
      }).then((geozones) => {
        const company = geozones as { exhibitor: Array<Record<string, Geozone[]>> };
        if (company && company.exhibitor && company.exhibitor.length > 0) {
          Object.keys(company.exhibitor[0]).forEach((k) => {
            const id = parseInt(k.replace('eGeo_', ''), 10);
            const code = this.events.find((e) => e.id === id)?.code as string;
            this.$set(this.geoZonesByEvent, code, company.exhibitor[0][k]);
          });
        }
      });
    }
  }

  private findUsOnMap(code: string): void {
    if (this.geoZonesByEvent
      && code in this.geoZonesByEvent
      && this.geoZonesByEvent[code]
      && this.geoZonesByEvent[code].length > 0) {
      const geozones = (this.geoZonesByEvent[code] as Geozone[]).map((b) => `${b.id}`) as string[];
      const { schemaCode } = this.geoZonesByEvent[code][0];
      const hallId = `${this.geoZonesByEvent[code][0].exhibitHall?.id}` as string;
      const filters = `${geozones.map((g, index) => `filters[geozones][${index}]=${g}`)}`;
      const route = `${this.$router.resolve({
        name: `${code}-map`,
        query: {
          edition: schemaCode,
          hall: hallId,
        },
      }).href}&${filters}`;
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        EntityType.EXHIBITOR,
        StatLoggerActions.MAP,
        this.events[this.selectedSubEditionIndex].code || '',
        -1,
        this.exhibitor.uid,
        StatLoggerCategories.EXHIBITOR_EVENT,
        this.$i18n.locale,
      );
      this.$router.push(route);
    }
  }

  private slide(direction: number): void {
    let val = this.selectedSubEditionIndex + direction;
    if (val < 0) {
      val = this.events.length - 1;
    }
    if (val > this.events.length - 1) {
      val = 0;
    }
    this.selectedSubEditionIndex = val;
  }

  private toggleVisited(): void {
    if (this.isVisited) {
      const temp = this.isVisited;
      this.isVisited = null;
      this.markUnvisited({ uid: temp as string | undefined })
        .catch(() => {
          this.isVisited = temp;
        });
    } else {
      this.isVisited = 'visited';
      this.markAsVisited({
        userId: this.authUser.uid,
        linkedEntityId: this.exhibitor.uid,
        entityType: EntityType.EXHIBITOR,
      }).then((response) => {
        this.isVisited = response?.uid || '';
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code || '',
          EntityType.EXHIBITOR,
          StatLoggerActions.VISIT,
          this.events[this.selectedSubEditionIndex].code || '',
          -1,
          this.exhibitor.uid,
          StatLoggerCategories.EXHIBITOR_EVENT,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.isVisited = null;
      });
    }
  }

  private handleBaseUrl(): void {
    this.$router.push({
      name: 'event-detail',
      params: { editionCode: this.events[this.selectedSubEditionIndex].code || '' },
    });
  }
}
